@font-face {
  font-family: 'CeraPro';
  src: url('./fonts/CeraPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  font-family: 'CeraPro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'CeraPro';
}